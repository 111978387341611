.slideshow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust this as per your requirement */
}

.slideshow img {
    max-width: 100%;
    transition: opacity 1s;
    position: absolute;
    opacity: 0;
}

.slideshow img.active {
    opacity: 1;
}